
import { defineComponent, reactive, toRef, toRefs, computed } from "vue";
import { useStore } from "@/store";
import { usePicker, PickerType, UfUploadFileType, useDate } from "@/composables";
import { userService } from "@/service";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";
import validate from "@/utils/validate";
const getDefaultForm = () => ({
  name: "",
  mobile: "",
  email: "",
  id_card: "",
  duty_name: "",
  stores_name: "",
  social_credit: "",
  place_name: "",
  owner_name: "",
  contact_phone: ""
});

interface DataProps {
  formData: ReturnType<typeof getDefaultForm>;
  idcardData: UfUploadFileType[];
  daterangePickerIsShow: boolean;
  daterangePickerData: Date[];
  daterangePicker2IsShow: boolean;
  daterangePicker2Data: Date[];
  licenseFile: UfUploadFileType[];
}

interface ErrorInfo<T> {
  values: T;
  errors: {
    name: string;
    message: string;
  }[];
}

export default defineComponent({
  name: "StoresAuth",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { picker: genderPicker } = usePicker(PickerType.Single.GENDER);
    const { picker: storesSubPicker } = usePicker(PickerType.Single.STORES_SUB_TYPE);
    const { picker: storesPicker } = usePicker(PickerType.Single.STORES_TYPE);
    const { daterangePickerDateFormat } = useDate();
    const mobile = computed(() => store.state.userInfo?.mobile);
    const storesData = reactive<DataProps>({
      formData: {
        ...getDefaultForm(),
        mobile: store.state.userInfo?.mobile as string
      },
      idcardData: [],
      daterangePickerIsShow: false,
      daterangePickerData: [],
      daterangePicker2IsShow: false,
      daterangePicker2Data: [],
      licenseFile: []
    });

    const validityOfIDCard = daterangePickerDateFormat(toRef(storesData, "daterangePickerData"));
    const validityOfStores = daterangePickerDateFormat(toRef(storesData, "daterangePicker2Data"));

    const onSubmit = async (values: DataProps["formData"]) => {
      try {
        await userService.authentication({
          type: "3",
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          gender: genderPicker.value,
          positive: storesData.idcardData[0]?.res.id,
          back: storesData.idcardData[1]?.res.id,
          id_card: values.id_card,
          start_time: validityOfIDCard.value.startTimestamp,
          end_time: validityOfIDCard.value.endTimestamp,
          stores_name: values.stores_name,
          duty_name: values.duty_name,
          stores_type: storesPicker.value,
          stores_sub: storesSubPicker.value,
          license: storesData.licenseFile[0]?.res.id,
          social_credit: values.social_credit,
          license_start_time: validityOfStores.value.startTimestamp,
          license_end_time: validityOfStores.value.endTimestamp,
          place_name: values.place_name,
          owner_name: values.owner_name,
          contact_phone: values.contact_phone
        });
        await router.push({
          name: RouterName.AUTHENTICATION_DETAIL
        });
      } catch (error) {
        console.log(error);
      }
    };

    const onFailed = (errorInfo: ErrorInfo<DataProps["formData"]>) => {
      Toast.fail(errorInfo.errors[0].message);
    };

    return {
      ...toRefs(storesData),
      onSubmit,
      onFailed,
      genderPicker,
      storesPicker,
      storesSubPicker,
      validityOfIDCard,
      validityOfStores,
      validate,
      mobile
    };
  }
});
